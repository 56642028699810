@import '../../CSS/base';

.container {
  border-radius: 0.5rem;
  padding: 2rem;
  background: $background-gradient;
  box-shadow: inset 0 0 10px #bbb;
  display: grid;
  grid-template-areas:
    'gif '
    'tech'
    'para'
    'link ';
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  position: relative;
}

.technology {
  grid-area: tech;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  gap: 1rem;
  color: $colour-secondary;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      filter: $colour-secondary-filter;
      height: 1.5rem;
      margin-bottom: 0.2rem;
    }
  }
}

.description {
  grid-area: para;
  & * + * {
    margin-top: 1rem;
  }
}

.links {
  grid-area: link;
  text-decoration: underline;

  a {
    color: $colour-primary;
  }
}
.links > * + * {
  margin-left: 1rem;
}

.gif {
  grid-area: gif;
  margin-top: 3rem;
  width: 100%;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.3);
}

@media (min-width: $media-break-point-computer-small) {
  .container {
    padding: 3rem;
    grid-template-areas:
      'tech tech gif gif gif'
      'para para gif gif gif'
      'para para gif gif gif'
      'para para gif gif gif'
      'link link gif gif gif';
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto auto auto auto auto;
    gap: 2rem;
  }

  .gif {
    margin-top: 0;
  }
}
