@import '../../CSS/base';

.header {
  width: 90vw;
  text-align: start;

  h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: $media-break-point-tablet) {
  .header {
    width: 60rem;
    h1 {
      font-size: 3.5rem;
    }
  }
}
