@import '../../CSS/base';

.container {
  --gap: 5rem;
  margin-top: 5rem;
}

.intro {
  font-size: 1.2rem;
  max-width: 60ch;
  padding: 3rem 0;
  margin-top: 4rem;
}

.subHeader {
  font-size: 2rem;
}

.education {
  position: relative;
  margin: 7rem 0 4rem 0;
}

.education_cards {
  margin: auto;
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  grid-template-rows: auto;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}
