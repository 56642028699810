@import '../../../CSS/base';

.card {
  background-color: transparent;
  width: 100%;
  max-width: 20rem;
  padding-top: unquote('min(100%, 20rem)');
  perspective: 1000px;
  position: relative;
  cursor: pointer;
}

.card_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card:hover .card_inner {
  transform: rotateY(180deg);
}

.card_front,
.card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card_front {
  box-sizing: border-box;
  background-color: #000;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  h3,
  p {
    margin: 0;
  }
  img {
    width: 50%;
  }
}

.card_back {
  background-color: #000;
  transform: rotateY(180deg);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
    z-index: 2;
  }
}
