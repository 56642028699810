@import '../../CSS/base';

.landing {
  font-size: 1.2rem;
  max-width: 60ch;
  padding: 5rem 0;
  margin: 0;
}

.contact {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
}

@media (min-width: $media-break-point-tablet) {
  .contact {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 20rem;

    p {
      margin-left: 2rem;
    }
  }
}
