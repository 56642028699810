@import './CSS/base';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // height: 100vh;
  // width: 100vw;
  background-size: 100%;

  background-image: url('./background.png');
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: 80% 0;
  background-size: 200%;
}

@media (min-width: $media-break-point-computer-small) {
  body {
    background-position: 100% 0;
    background-size: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-title;
}

h1 {
  font-size: 3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: $media-break-point-computer-large) {
  html {
    font-size: 1.5rem;
  }
}
