@import '../../CSS/base';

.container {
  display: none;
}

@media (min-width: $media-break-point-computer-small) {
  .container {
    display: block;
  }
}
