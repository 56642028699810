.App {
  min-height: 100vh;
  position: relative;
  padding-bottom: 9rem;
}

.page {
  overflow: hidden;
  min-height: 100vh;
}

.landingPage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5vw;
}
