@import '../../CSS/base';

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4rem 0;
  p {
    color: #666;
    font-size: 1.3rem;
    font-family: $font-title;
    font-style: italic;
    margin-top: 2rem;
    max-width: 40ch;
  }
}

.section {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.section_paragraph {
  font-size: 1.1rem;
  max-width: 60ch;
  width: 100%;
}

.section_paragraph > * + * {
  margin-top: 1rem;
}

.section_images {
  margin-top: 2rem;
  max-width: 80vw;
  height: 80vw;
  position: relative;

  img {
    width: 80%;
  }
  img + img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.section_subtitle {
  color: $colour-primary;
  font-size: 1.7rem;
}

.profile {
  width: 15rem;
  height: 15rem;
  box-shadow: $shadow;
  border-radius: 50%;
  background-color: #fff;
  object-fit: cover;
}

@media (min-width: $media-break-point-tablet) {
  .intro {
    flex-direction: row;
    justify-content: start;
  }

  .profile {
    margin: 0 3rem 0 0;
  }

  .section {
    flex-direction: row;
    justify-content: space-between;
  }

  .section_images {
    margin-top: 0;
    margin-left: 2rem;
    max-width: 35vw;
    height: 35vw;
  }

  .section + .section {
    flex-direction: row-reverse;

    .section_images {
      margin-left: 0;
      margin-right: 2rem;

      img + img {
        z-index: -1;
      }
    }
  }
}
