@import '../../../CSS/base';

.typeContainer {
  display: flex;
  align-items: flex-start;

  h1 {
    font-size: 1.5em;
  }
}

.typewriter {
  font-size: 1.5rem;
  width: 90vw;
  color: rgb(212, 96, 0);
  letter-spacing: 0.15em;
  animation: typing 3s steps(40, end);
  animation-direction: alternate;
}

.typewriterTwo {
  font-size: 1.5rem;
  width: 90vw;
  color: rgb(212, 96, 0);
  letter-spacing: 0.15em;
  animation: typing 3s steps(40, end);
  animation-direction: alternate;
}

.blink {
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (min-width: $media-break-point-tablet) {
  .typeContainer {
    h1 {
      font-size: 2.5rem;
    }
  }
  .typewriter {
    font-size: 2rem;
    width: 0%;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 3s steps(40, end);
    animation-direction: alternate;
  }

  .typewriterTwo {
    font-size: 2rem;
    width: 0%;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 3s steps(40, end);
    animation-direction: alternate;
  }

  @keyframes typing {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }
}
