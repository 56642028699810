@import '../../../CSS/base';

.container {
  z-index: 1;
  display: flex;
  width: 100vw;
  height: 4rem;
  background-color: white;
  border-radius: 0.5rem 0 0 0.5rem;
  align-items: center;
  justify-content: felx-start;
  text-decoration: none;
  transform: translateX(-70vw);
  transition-property: transform;
  transition-duration: 0.5s;
  margin-top: 1rem;
  box-shadow: $shadow;

  img {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;

    filter: invert(70%) sepia(30%) saturate(3970%) hue-rotate(355deg)
      brightness(100%) contrast(91%);
  }

  p {
    font-size: 1.3rem;
    margin-right: 1rem;
    color: black;
    font-family: $font-title;
  }
}

.hidden {
  transform: translateX(50vw);
}

.active {
  background-color: $colour-secondary;
  img {
    filter: invert(100%);
  }
  p {
    color: #fff;
  }
}

.delayOne {
  transition-delay: 0ms;
}

.delayTwo {
  transition-delay: 100ms;
}

.delayThree {
  transition-delay: 200ms;
}

.delayFour {
  transition-delay: 300ms;
}

@media (min-width: $media-break-point-computer-small) {
  .container {
    transform: translateX(-5rem);
    transition-duration: 0.5s;
    justify-content: flex-start;

    p {
      margin-left: 2rem;
    }

    &:hover {
      transform: translateX(-20rem);
    }
  }
}
