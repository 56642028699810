@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');

$font-title: 'Bitter', serif;
$font-paragraph: 'Raleway', sans-serif;

$media-break-point-tablet: 700px;
$media-break-point-phone: 500px;
$media-break-point-computer-small: 1000px;
$media-break-point-computer-large: 1800px;

$colour-primary: #f39c12;
$colour-primary-filter: invert(70%) sepia(30%) saturate(3970%)
  hue-rotate(355deg) brightness(90%) contrast(91%);
$colour-secondary: #2e1e33;
$colour-secondary-filter: invert(9%) sepia(36%) saturate(878%)
  hue-rotate(241deg) brightness(98%) contrast(89%);
$colour-purple: rgb(92, 64, 95);
$colour-red: rgb(49, 41, 41);
$colour-green: rgb(55, 116, 97);
$colour-blue: rgb(84, 101, 128);
$colour-dark-grey: #333;
$colour-grey: #666;
$colour-light-grey: #eee;

$spacer: 1rem;

$shadow: -5px 5px 12px rgba(0, 0, 0, 0.35);
$shadow-sm: 2px 2px 4px rgba(0, 0, 0, 0.3);

$background-gradient: linear-gradient(
  -45deg,
  rgba(180, 180, 180, 0.7) 0%,
  rgba(255, 255, 255, 0.7) 40%,
  rgba(255, 255, 255, 0.7) 60%,
  rgba(180, 180, 180, 0.7) 100%
);
