@import '../../CSS/base';

.cardContainer {
  margin: auto;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
}

.cardContainer > * {
  margin-top: 7rem;
}

.filter_form {
  display: flex;
  flex-direction: column;

  label {
    font-family: $font-title;
    font-size: 1.5rem;
    color: #222;
  }
}

.filter_select {
  width: 100%;
  margin: 1rem 0 0 0;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  color: #000;
  background-color: #fff;
}

@media (min-width: $media-break-point-tablet) {
  .filter_form {
    flex-direction: row;
    align-items: center;
  }

  .filter_select {
    margin: 0 0 0 2rem;
    width: 15rem;
  }
}
