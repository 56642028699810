@import '../../CSS/base';

.header {
  font-size: 2rem;
  color: #111;
  position: relative;
  margin: 5vw;
  animation: typing 1.5s ease-in 0s 1;
  overflow: hidden;
  white-space: nowrap;
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  filter: invert(90%);
  top: 0;
  left: 0;
  transform: rotate(-30deg);
  height: 100vh;
  width: 100vh;
  animation: roll-in 1.3s ease-out 0s 1;
}

.downArrow {
  width: 3rem;
  position: absolute;
  bottom: 2rem;
  right: 50%;
  transform: translateX(50%);
}

.blink {
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes roll-in {
  0% {
    transform: translateX(-50vw) rotate(110deg);
  }
  100% {
    transform: translateX(0) rotate(330deg);
  }
}

@media (min-width: $media-break-point-tablet) {
  .header {
    font-size: 3rem;
    padding: 2rem 0 4rem 0;
    animation: typing 2.5s ease-in 0s 1;
  }
}
