@import '../../CSS/base';

.container {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 2.5rem 0 1rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  --gap: 2rem;

  a {
    opacity: 0.7;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  img {
    height: 2rem;
    filter: $colour-primary-filter;
  }
}
