@import '../../CSS/base';

.container {
  height: 1;
  width: 1;
  display: block;
}

.menuContainer {
  z-index: 10;
  position: fixed;
  right: 15px;
  top: 15px;
  background-color: $colour-primary;
  border-radius: 10000px;
  box-shadow: $shadow-sm;
}

.menu {
  height: 2rem;
  width: 2rem;
  margin: 1rem;
  position: relative;
  div {
    transition: all 0.2s;
    border-radius: 1000px;
    width: 2rem;
    height: 2px;
    background-color: white;
  }
  :nth-child(1) {
    position: absolute;
    top: 10%;
  }
  :nth-child(2) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  :nth-child(3) {
    position: absolute;
    bottom: 10%;
  }
}

.open {
  justify-content: center;
  :nth-child(1) {
    transform-origin: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(225deg);
  }
  :nth-child(2) {
    opacity: 0;
  }
  :nth-child(3) {
    transform-origin: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(315deg);
  }
}

@media (min-width: $media-break-point-phone) {
  .container {
    width: 50vw;
  }
}

@media (min-width: $media-break-point-computer-small) {
  .container {
    display: none;
  }
}
