@import '../../CSS/base';

.container {
  z-index: 10;
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 0vw;
}

.conatiner > * + * {
  margin-top: 0.5rem;
}
